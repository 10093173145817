export function demos(){
    
    const id = document.getElementById("demo");
    if(id != null){

        //VEGAS
        const target = $(".firstview");
        const photo = new Array();
        for(let i=0; i < target.find(".hide img").length; i++){
            photo[i] = target.find(".hide img").eq(i).attr("src");
        }
        const photos = Object.entries(photo).map(([src, value]) => ({'src': value}));
        target.vegas({
            overlay: false,
            timer:false,
            transitionDuration: 2000,
            delay: 10000,
            animation: 'kenburns',
            animationDuration: 20000,
            loop:false,
            slides: photos
        });


        //SWIPER
        const swiper = new Swiper('.swiper-container', {
            slidesPerView: 3,
            spaceBetween: 30,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                768:{
                    slidesPerView: 1,
                    spaceBetween: 0
                }
            }
        });
    }
}